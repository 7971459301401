<template>
  <b-card-code>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
      <div class="custom-search d-flex justify-content-start">
          <vue-excel-xlsx
              :data="getData"
              :columns="columns10"
              :filename="'filename'"
              :sheetname="'payments'"
          >
              <b>Excel</b>
          </vue-excel-xlsx>
          <vue-json-to-csv :json-data="getData"
                           :csv-title="'My_CSV'"
          >
              <button>
                  <b>CSV</b>
              </button>
          </vue-json-to-csv>
          <button @click="pdfGenerate()">
              <b>PDF</b>
          </button>
      </div>

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="admissionPayments"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <span v-else-if="props.column.field === 'classes_id'">
          {{ props.row.admission?classByID(props.row.admission.classes_id):'' }}
        </span>
        <span v-else-if="props.column.field === 'version_id'">
          {{ props.row.admission?versionByID(props.row.admission.version_id):''}}
        </span>
        <span v-else-if="props.column.field === 'wing_id'">
          {{ props.row.admission?wingByID(props.row.admission.wing_id):''}}
        </span>
        <span v-else-if="props.column.field === 'student_group_id'">
          {{ props.row.admission?student_groupByID(props.row.admission.student_group_id):''}}
        </span>
        <span v-else-if="props.column.field === 'academic_year_id'">
          {{ academic_yearByID(props.row.academic_year_id)}}
        </span>
        <span v-else-if="props.column.field === 'status'">
          <b-badge v-if="props.row.status==='Failed' || props.row.status==='Canceled'" variant="danger">{{props.row.status}}</b-badge>
          <b-badge v-else-if="props.row.status==='Pending'" variant="warning" class="text-black-50">{{props.row.status}}</b-badge>
          <b-badge v-else variant="success" class="text-black-50">{{props.row.status}}</b-badge>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRecords"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BPagination, BFormGroup, BFormInput, BFormSelect,BBadge,
} from 'bootstrap-vue'
import {required,integer} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import moment from "moment";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";

Vue.use(VueExcelXlsx);
export default {
  name:'AdmissionPayment',
  components: {
    BCardCode,
    BPagination,
    BFormGroup,
    BFormInput,BBadge,
    BFormSelect,VueGoodTable,
    VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      admissionPayments:[],
      required,
      pageLength: 20,
      totalRecords: 0,
      currentPage:1,
      nextPage: 1,
      dir: false,
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Track No',
          field: 'admission.track_no',
        },
        {
          label: 'Mobile No',
          field: 'admission.guardian_mobile',
        },
        {
          label: 'Name',
          field: 'admission.name',
        },
        {
          label: 'Class',
          field: 'classes_id',
        },
        // {
        //   label: 'Version',
        //   field: 'version_id',
        // },
        {
          label: 'Wing',
          field: 'wing_id',
        },
        // {
        //   label: 'Student Group',
        //   field: 'student_group_id',
        // },
        {
          label: 'Academic year',
          field: 'academic_year_id',
        },
        {
          label: 'Fee Amount',
          field: 'fee_amount',
        },
        {
          label: 'Pay Amount',
          field: 'pay_amount',
        },
        {
          label: 'Status',
          field: 'status',
        },
      ],
      searchTerm: '',
      integer,
      student_group_id:null,
      version_id:null,
      classes_id:null,
      class_period_id:null,
      academic_year_id:null,
      wing_id:null,
      columns10: [
            {
              label: 'TrackNo',
              field: 'TrackNo',
            },
            {
              label: 'Mobile NO',
              field: 'MobileNO',
            },
            {
              label: 'Name',
              field: 'Name',
            },
            {
                label: 'Class',
                field: 'Class',
            },
            {
                label: 'Version',
                field: 'Version',
            },
            {
                label: 'Wing',
                field: 'Wing',
            },
            {
                label: 'Student Group',
                field: 'StudentGroup',
            },
            {
                label: 'Academic Year',
                field: 'AcademicYear',
            },
            {
                label: 'Fee Amount',
                field: 'FeeAmount',
            },
            {
              label: 'Pay Amount',
              field: 'payAmount',
            },
            {
              label: 'Status',
              field: 'Status',
            },
        ],
      mode:'add',
    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.nextPage = value;
        this.loadAdmissionInfo();
      },
    },
  },
  methods:{
    async loadAdmissionInfo(){
      await apiCall.get('/admission/payments?per_page='+this.pageLength +'&page='+ this.nextPage).then((response)=>{
          this.admissionPayments=response.data.data;
          this.totalRecords = response.data.total;
        }).catch(()=>{
          this.admissionPayments=[];
        })
    },
    pdfGenerate(){
          /*only change able*/
          let title="Admission Payments";
          let clm=['TrackNo','MobileNO','Name','Class','Version','Wing','StudentGroup',
            'AcademicYear','FeeAmount','PayAmount','Status'];
          /*change able end*/
          let data =new FormData();
          data.append('title',title);
          data.append('columns',JSON.stringify(clm));
          data.append('data',JSON.stringify(this.getData));
          apiCall.post('/get/common/table/export/pdf',data,{responseType: 'blob'}).then((response)=>{
              const content = response.headers['content-type'];
              download(response.data,'admissionPayment.pdf', content)
          }).catch((error) => {
              this.$toaster.error(error.response.data.message);
          });
      },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters([
        'academic_years','academic_yearByID',
      'classes','classByID','versions','versionByID','student_groups','student_groupByID',
        'wings','wingByID'
    ]),
      getData(){
          return this.admissionPayments.map(item => ({
              MobileNO: item.admission?item.admission.guardian_mobile:'',
              TrackNo: item.admission?item.admission.track_no:'',
              Name: item.admission?item.admission.name:'',
              Class: item.admission?this.classByID(item.admission.classes_id):'',
              Version: item.admission?this.versionByID(item.admission.version_id):'',
              Wing: item.admission?this.wingByID(item.admission.wing_id):'',
              StudentGroup: item.admission?this.student_groupByID(item.admission.student_group_id):'',
              AcademicYear: item.admission?this.academic_yearByID(item.admission.academic_year_id):'',
              FeeAmount: item.fee_amount?item.fee_amount:'',
              PayAmount: item.pay_amount?item.pay_amount:'',
              Status: item.status?item.status:'',
          }));
      },
    user(){
      return JSON.parse(localStorage.getItem('userData'))
    }
  },
  created() {
   // this.$store.dispatch('GET_ALL_ADMISSION_PAYMENT');
    this.loadAdmissionInfo();
    if(this.classes.length <1) this.$store.dispatch('GET_ALL_CLASSES');
    if(this.versions.length <1) this.$store.dispatch('GET_ALL_VERSION');
    if(this.wings.length <1) this.$store.dispatch('GET_ALL_WING');
    if(this.student_groups.length <1) this.$store.dispatch('GET_ALL_STUDENT_GROUP');
    if(this.academic_years.length <1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>